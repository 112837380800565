'use client';

import Image, { ImageLoaderProps } from 'next/image';
import classNames from 'classnames';
import { FC, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { AnimatePresence, motion as m } from 'framer-motion';
import { useClientConfig } from '@spikemark/core';
import { ImageIcon } from 'lucide-react';
import { Tooltip, TooltipContent, TooltipTrigger } from './ui/tooltip';

type MascotVariant =
  | 'tiny'
  | 'xSmall'
  | 'small'
  | 'print'
  | 'badge'
  | 'medium'
  | 'mprint'
  | 'large'
  | 'xlarge'
  | 'xxlarge'
  | 'input';

// Only display the loading animation if we have been loading for this amount of
// time. If we load the image quickly - likely cached - then we don't need to show
// the animation.
const LOADING_ANIMATION_START_DELAY_MS = 200;

export const DEFAULT_AVATAR_PATH =
  'M9 0a9 9 0 0 0-9 9 8.654 8.654 0 0 0 .05.92 9 9 0 0 0 17.9 0A8.654 8.654 0 0 0 18 9a9 9 0 0 0-9-9zm5.42 13.42c-.01 0-.06.08-.07.08a6.975 6.975 0 0 1-10.7 0c-.01 0-.06-.08-.07-.08a.512.512 0 0 1-.09-.27.522.522 0 0 1 .34-.48c.74-.25 1.45-.49 1.65-.54a.16.16 0 0 1 .03-.13.49.49 0 0 1 .43-.36l1.27-.1a2.077 2.077 0 0 0-.19-.79v-.01a2.814 2.814 0 0 0-.45-.78 3.83 3.83 0 0 1-.79-2.38A3.38 3.38 0 0 1 8.88 4h.24a3.38 3.38 0 0 1 3.1 3.58 3.83 3.83 0 0 1-.79 2.38 2.814 2.814 0 0 0-.45.78v.01a2.077 2.077 0 0 0-.19.79l1.27.1a.49.49 0 0 1 .43.36.16.16 0 0 1 .03.13c.2.05.91.29 1.65.54a.49.49 0 0 1 .25.75z';

export const variants: Record<
  MascotVariant,
  { width: number; height: number; padding: number; border: boolean }
> = {
  tiny: {
    width: 16,
    height: 16,
    padding: 2,
    border: false,
  },
  xSmall: {
    width: 24,
    height: 24,
    padding: 2,
    border: false,
  },
  small: {
    width: 32,
    height: 32,
    padding: 4,
    border: false,
  },
  badge: {
    width: 40,
    height: 40,
    padding: 4,
    border: false,
  },
  print: {
    width: 48,
    height: 48,
    padding: 4,
    border: false,
  },
  medium: {
    width: 76,
    height: 76,
    padding: 12,
    border: true,
  },
  mprint: {
    width: 80,
    height: 80,
    padding: 12,
    border: true,
  },
  large: {
    width: 120,
    height: 120,
    padding: 20,
    border: true,
  },
  xlarge: {
    width: 160,
    height: 160,
    padding: 28,
    border: true,
  },
  xxlarge: {
    width: 200,
    height: 200,
    padding: 60,
    border: true,
  },
  input: {
    width: 100,
    height: 100,
    padding: 15,
    border: true,
  },
};

export type MascotProps = {
  path?: string;
  variant: MascotVariant;
  displayMode?: 'team' | 'player';
  alt: string;
  priority?: boolean;
  className?: string;
  border?: boolean;
};

export const Mascot: FC<MascotProps> = ({
  path,
  variant: variantName,
  alt,
  displayMode = 'team',
  priority,
  className,
  border: propBorder,
}) => {
  const { cloudinary } = useClientConfig();

  const teamLoader = useCallback(
    ({ variant }: { variant: MascotVariant }) =>
      ({ src, width, quality }: ImageLoaderProps) => {
        return `${cloudinary.basePath}${src}?tx=w_${width},ar_1,q_${quality},c_fit`;
      },
    [cloudinary]
  );

  const playerLoader = useCallback(
    ({ variant }: { variant: MascotVariant }) =>
      ({ src, width, quality }: ImageLoaderProps) => {
        return `${cloudinary.basePath}${src}?tx=w_${width},ar_1,q_${quality},g_face:center,${
          /large/.test(variant) ? 'c_fill' : 'c_thumb,z_0.9'
        }`;
      },
    [cloudinary]
  );

  const variant = variants[variantName];
  const border = propBorder || true;
  const padding = displayMode === 'team' ? variant.padding : 0;
  const [hasError, setHasError] = useState<boolean>(false);
  const [hasLoaded, setHasLoaded] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { width, height } = variant;
  const contentWidth = width - padding * 2;
  const contentHeight = height - padding * 2;
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  const handleComplete = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
    setIsLoading(false);
    setHasLoaded(true);
  }, []);
  const handleLoadStart = useCallback(() => {
    timeoutRef.current = setTimeout(() => {
      setIsLoading(true);
    }, LOADING_ANIMATION_START_DELAY_MS);
  }, []);
  const handleLoad = useCallback(() => {
    handleComplete();
  }, [handleComplete]);
  const handleError = useCallback(() => {
    handleComplete();
    setHasError(true);
  }, [handleComplete]);

  const loader = useMemo(() => {
    const loaderFactory = displayMode === 'team' ? teamLoader : playerLoader;
    return loaderFactory({ variant: variantName });
  }, [displayMode, variantName]);

  useLayoutEffect(() => {
    if (path && !hasLoaded) {
      handleLoadStart();
    }
  }, [path, hasLoaded, handleLoadStart]);

  useEffect(() => {
    // If the path changes, reset hasLoaded
    setHasLoaded(false);
  }, [path]);

  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <div
          className={classNames(
            className,
            'rounded-full overflow-hidden dark:bg-white print:hidden',
            {
              'border-zinc-200 inline-block box-content': border,
              'border-2': border && variantName !== 'tiny',
              border: variantName === 'tiny',
              'border border-transparent': !border,
            }
          )}
          data-variant={variantName}
        >
          <div
            className={classNames('relative', {
              'bg-white rounded-full flex items-center justify-center': border,
            })}
            style={{
              width,
              height,
            }}
          >
            {path && !hasError ? (
              <Image
                key={path}
                alt={alt}
                src={path}
                loader={loader}
                quality={90}
                width={displayMode === 'player' ? Math.ceil(contentWidth * 1.5) : contentWidth}
                height={displayMode === 'player' ? Math.ceil(contentHeight * 1.5) : contentHeight}
                style={{
                  width: contentWidth,
                  height: contentHeight,
                  minWidth: contentWidth,
                }}
                className={classNames('flex-shrink-0', {
                  'object-center object-contain': displayMode === 'team',
                  'object-center object-cover rounded-full': displayMode === 'player',
                  'opacity-0': isLoading,
                  'opacity-1': !isLoading,
                })}
                onError={handleError}
                onLoad={handleLoad}
              />
            ) : (
              <>
                {displayMode === 'player' ? (
                  <svg
                    width={contentWidth}
                    height={contentHeight}
                    viewBox="1 1 16 16"
                    className="rounded-full bg-white"
                  >
                    <path className="fill-zinc-300" d={DEFAULT_AVATAR_PATH} />
                  </svg>
                ) : (
                  <div
                    style={{ width: contentWidth, height: contentHeight }}
                    className="bg-gray-300/50 rounded-full hover:bg-gray-300 flex items-center justify-center text-gray-600 text-light"
                  >
                    <ImageIcon width={contentHeight * 0.35} height={contentHeight * 0.35} />
                  </div>
                )}
              </>
            )}
            <AnimatePresence>
              {path && isLoading && !hasError ? (
                <m.div
                  className="absolute inset-0 rounded-full overflow-hidden"
                  transition={{
                    duration: 0.3,
                    ease: 'easeOut',
                  }}
                  exit={{ opacity: 0 }}
                >
                  <m.div
                    className="absolute top-0 w-full bg-zinc-300"
                    initial={{ top: -20, height: 20 }}
                    animate={{ top: height - 3, height: 6 }}
                    transition={{
                      repeat: Infinity,
                      repeatType: 'reverse',
                      duration: 1,
                      ease: [0.86, 0, 0.07, 1],
                    }}
                  />
                  <div className="absolute inset-0.5 rounded-full bg-zinc-100" />
                </m.div>
              ) : null}
            </AnimatePresence>
          </div>
        </div>
      </TooltipTrigger>
      <TooltipContent side="right" sideOffset={-6} align="center">
        {alt}
      </TooltipContent>
    </Tooltip>
  );
};
